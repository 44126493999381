@import "notosans-fontface/scss/notosans-fontface";

@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL Mode
// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/general-rtl";
// @import "./custom/rtl/pages-rtl";
// @import "./custom/rtl/plugins-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/structure-rtl";
// @import "./custom/rtl/text-rtl";

body {
  font-family: "Noto Sans KR", sans-serif;
  background-color: #fff;
}

iframe {
  display: none;
}
#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.dropdown-menu-end {
  left: auto !important;
  right: 0 !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

.titleLineWrap {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.titleLine {
  width: 100%;
  border: 1px solid #7298ff;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  border: 0;
  font-size: 15px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 10px;
  opacity: 0.5;
  width: 2em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 111111 !important;
}

// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}

@media (min-width: 200px) and (max-width: 992px) {
  .recharts-wrapper {
    width: unset !important;
  }
  .recharts-surface {
    width: 100%;
  }
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
  display: block;
}

.is-loading {
  position: relative;
}

.form-control {
  appearance: auto !important;
}
// .container {
//   max-width: 1600px !important;
// }

.table > :not(:first-child) {
  border-top: none !important;
}

.form-control:focus {
  border-color: #ced4da !important;
}
.pdfInvoice {
  display: none;
}

.papermui {
  width: 100vw !important;
  left: 0 !important;
  box-shadow: none !important;
  padding: 30px 0px !important;
}

.pro-sidebar > .pro-sidebar-inner {
  padding-bottom: 15px;
}
// .k-grid-header-wrap > table {
//   width: 100% !important;
// }
// .k-grid-header-wrap > table > colgroup {
//   width: 100% !important;
// }
// .k-grid-header-wrap > table > colgroup > col:last-child {
//   width: 100% !important;
// }
// .k-grid-table {
//   width: 100% !important;
// }
// .k-grid-table > colgroup {
//   width: 100% !important;
// }
// .k-grid-table > colgroup > col:last-child {
//   width: 100% !important;
// }
@media print {
  .shopHeaderSearchWrap {
    display: none !important;
  }
  #downloadBtn {
    display: none !important;
  }
  #shopFooter {
    display: none !important;
  }
  .shopHeaderWrap {
    display: none !important;
  }
  #shopHedearmui {
    display: none !important;
  }
  #shopHedearmuimargin {
    display: none !important;
  }
  #test {
    display: none !important;
  }
  .react-cookie-banner {
    display: none !important;
  }
  .invoiceMain {
    display: none !important;
  }
  .pdfInvoice {
    display: block;
  }
}

.k-grid {
  height: 100%;
}
