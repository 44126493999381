/* ========================================================================
   Component: layout-animation.less index
 ========================================================================== */

// .wrapper .aside-container {
//     @include transition(#{"width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1)"});
// }

// .aside-inner,
// .navbar-header,
// .sidebar>.sidebar-nav>li {
//     transition: width .2s cubic-bezier(0.35, 0, 0.25, 1);
// }

// .wrapper .section-container {
//     transition: margin-left .2s cubic-bezier(0.35, 0, 0.25, 1);
// }

// .sidebar>.sidebar-nav .badge {
//     animation: fadeInRight 1s;
//     animation-fill-mode: both;
// }

// .aside-collapsed .sidebar>.sidebar-nav .badge,
// .aside-collapsed-text .sidebar>.sidebar-nav .badge {
//     animation: fadeIn 1s;
// }

// .sidebar .sidebar-nav>li>a {
//     animation: fadeInLeft .5s;
// }

// .sidebar>.sidebar-nav>.nav-heading,
// .sidebar>.sidebar-nav>li>a>span,
// .navbar-brand .brand-logo {
//     animation: fadeIn 1s;
// }

// .sidebar li>a,
// .sidebar li>.nav-item,
// .sidebar>.sidebar-nav>.nav-heading {
//     white-space: nowrap;
// }

// .aside-collapsed,
// .aside-collapsed-text {
//     .user-block-picture {
//         transition: width .2s cubic-bezier(0.35, 0, 0.25, 1);
//     }
//     .user-block {
//         transition: padding .2s cubic-bezier(0.35, 0, 0.25, 1);
//     }
// }

/* ========================================================================
   Component: layout-animation.less base
 ========================================================================== */

.wrapper .aside-container {
  @include transition(
    #{"width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1)"}
  );
}

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
}

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar > .sidebar-nav .badge {
  animation: fadeInRight 1s;
  animation-fill-mode: both;
}

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  animation: fadeIn 1s;
}

.sidebar .sidebar-nav > li > a {
  animation: fadeInLeft 0.5s;
}

.sidebar > .sidebar-nav > .nav-heading,
.sidebar > .sidebar-nav > li > a > span,
.navbar-brand .brand-logo {
  animation: fadeIn 1s;
}

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap;
}

.aside-collapsed,
.aside-collapsed-text {
  .user-block-picture {
    transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  }
  .user-block {
    transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  }
}
