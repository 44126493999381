.KR-000- {
  height: 100vh;
  background-color: #f7f7f7;
}

#loginPageWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #373737;
}

.loginSubText {
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.loginInputText {
  border: none !important;
  border-bottom: 1px solid #707070 !important;
  border-radius: 0% !important;
  padding: 0px !important;
  background-color: #373737 !important;

  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: #b3b3b3 !important;
}

.LoginWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginCardInputWrap {
  padding: 0 50px;
}

.titleWrap {
  margin-top: 31px;
  margin-bottom: 20px;
}

.title {
  width: 62px;
  height: 29px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7da0fe;
}

.IdWrap {
  margin: 0 0 20px 0;
  display: flex;
  align-items: center;
}
.idspan {
  height: 100%;
  font-family: Roboto;
  font-size: 20px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.input325 {
  height: 55px;
  padding: 16px 20px 15px 20px !important;
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
  font-family: Roboto;
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727 !important;
}

.PwWrap {
  display: flex;
  align-items: center;
  margin: 0 0 40px 0;
}

.pwspan {
  height: 100%;
  font-family: Roboto;
  font-size: 20px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.inputpw325 {
  height: 55px;
  padding: 16px 20px 15px 20px !important;
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727 !important;
}

.loginCheckLabel {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.loginButton {
  border: solid 1px #3d6ad0 !important;
  background-color: #3d6ad0 !important;
}

.btn326 {
  width: 540px;
  height: 55px;
  margin: 0 0 31px 0;
  border: none;
  border-radius: 8px;
  background-color: #7398ff;
  span {
    width: 52px;
    height: 24px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.loginSignUp {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b3 !important;
}

.signup {
  color: #272727;
  text-decoration: none;
  margin: 0px 20px;
}

.loginForgot {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #b3b3b3 !important;
}

.forgot {
  color: #272727;
  text-decoration: none;
  margin: 0px 20px;
}

.passwordShow {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3d6ad0;
}

@media screen and (max-width: 720px) {
  .loginCardInputWrap {
    width: 100%;
    padding: 0 50px;
  }

  .btn326 {
    width: 100%;
  }
}

.form-group {
  label {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
}
