.active > .nav-item > svg {
  fill: #fff !important;
}
.open > .nav-item > svg {
  fill: #fff;
}

.nav-item {
  &:hover {
    .sideicon {
      fill: #fff;
    }
  }
}
.sideicon {
  fill: #8fa6bf;
  margin-right: 10px;
  margin-left: 10px;
  max-width: 26px;
  max-height: 26px;
}
