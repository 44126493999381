@import "../../assets/scss/theme.scss";

.bankslipmodal > .kendogridWrap > .k-grid > .k-grid-header {
  position: relative !important;
  top: 0px !important;
}

#headerTilteID {
  display: none;
}
// .k-grid-content {
//   &::-webkit-scrollbar {
//     position: sticky !important;
//     bottom: 0 !important;
//     z-index: 1111111 !important;
//   }
// }

.masterHeader {
  width: 100%;
  margin: 0px;
  padding: 17px 20px !important;
  border-radius: 5px;
  background-color: #f4f4f4;
}

#masterPlusBtn {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #ced4da;
}

.fileAddName {
  width: fit-content;
}

#masterSearchBtn {
  border-radius: 5px;
  background-color: #ff5913;
  color: #fff;
  border: none;
}

.skutableText {
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
}

.MasterTitle {
  font-size: 28px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323232;
}
// #headerTilteID {
//   display: none;
// }

.MasterSubTitle {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

#selec {
  appearance: auto !important;
}

.masterlabel {
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000 !important;
  margin: 0;
}

.simplebar-wrapper {
  height: 100% !important;
}

.simplebar-content-wrapper {
  height: 100% !important;
}

.simplebar-content {
  height: 100% !important;
}

.activebrandCol {
  color: #3d6ad0;
}

.brandCol {
  :hover {
    color: #5481e7;
  }
}

.brandspan {
  cursor: pointer;
}

.activebrandspan {
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 2px solid #3d6ad0;
}

.pagination_item {
  .page-link {
    padding: 5px 10px;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    padding: 0;
    margin: 0;
    color: #272727;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff5913 !important;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
}

.cardhead {
  background-color: #373737 !important;
}

#btnColor {
  color: #707070 !important;
}

#selfileBtn {
  color: #707070 !important;
}
input[type="file"] {
  display: none;
}

.focusRemark {
  outline: none !important;
  box-shadow: none !important;
  resize: none !important;
}

.focusRemark:focus {
  border: 1px solid #ff6900 !important;
}

.hoverCal:hover {
  cursor: pointer;
  color: #ff6900;
}

.invoiceCard {
  border: none !important;
  background-color: #ebebeb !important;
  height: 172px;
  padding: 23px 0px 23px 22px !important;
}

.mainText {
  font-size: 18px;
  font-weight: 900;
}

.subText {
  font-size: 18px;
}

.invoiceRemarkCard {
  height: 172px;
  padding: 23px 50px !important;
}

.remarkScroll::-webkit-scrollbar {
  width: 12px;
  background-color: #fff;
}

.remarkScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ff6900;
}

#downloadBtn {
  background-color: #373737;
  border: 1px solid #373737;
  color: #fff !important;
}
#companionBtn {
  background-color: #fff;
  border: 1px solid #2b2b2b;
  color: #373737 !important;
  width: 195px;
}
#confirmBtn {
  background-color: #fff;
  border: 1px solid #ff5913;
  color: #ff6900 !important;
  width: 195px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.manageInvoiceDownBtn {
  width: 34px;
  height: 34px;
  background-image: url("../../assets/icons/spigeniconsvg/upload_black.svg");
  background-repeat: round;
  border: none;
}

.manageInvoiceDownBtn:active {
  width: 34px;
  height: 34px;
  background-image: url("../../assets/icons/spigeniconsvg/upload_orange.svg");
  background-repeat: round;
  border: none;
}

#monthDrop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #212529 !important;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.checkColor:checked {
  background-color: #ff6900 !important;
  border-color: #ff6900 !important;
}
#trueBtn {
  font-size: 14px !important;
  background-color: #ff5913 !important;
  border: 1px solid #ff5913 !important;
  color: #fff;
}

#falseBtn {
  font-size: 14px !important;
  background-color: #fff !important;
  border: 1px solid #707070 !important;
  color: #707070 !important;
}

.totaltable {
  background-color: #c7c7c7 !important;
  color: #fff;
}

.textellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

.textellipsis:hover {
  overflow: visible;
}

.cartRemoveBtn {
  border: none !important;
  background-color: #ff5913 !important;
}

.cartRemoveBtn:active {
  background-color: #373737 !important;
}

#ordercheckconfirmBtn {
  background-color: #fff;
  border: 1px solid #ff5913;
  border-radius: 5px;
  color: #ff6900 !important;
  font-size: 18px;
  font-weight: bold;
  padding: 14px 63px !important;
}

#ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  max-width: 150px;
}

#ellipsiss {
  white-space: normal;
  word-wrap: break-word;
  max-width: 150px;
}

.acceptBtn {
  background-color: #0576b9 !important;
  border: none !important;
}

.dangerBtn {
  background-color: #e41818 !important;
  border: none !important;
}

.css-1k51tf5-MuiTooltip-tooltip {
  max-width: 50px !important;
}

.font5 {
  color: windowtext;
  font-size: 8pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
}

.style0 {
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  white-space: nowrap;
  mso-rotate: 0;
  mso-background-source: auto;
  mso-pattern: auto;
  color: black;
  font-size: 11pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
  border: none;
  mso-protection: locked visible;
  mso-style-name: 표준;
  mso-style-id: 0;
}
.style17 {
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  white-space: nowrap;
  mso-rotate: 0;
  mso-background-source: auto;
  mso-pattern: auto;
  color: black;
  font-size: 11pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
  border: none;
  mso-protection: locked visible;
  mso-style-name: "표준 149";
}
.style18 {
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  white-space: nowrap;
  mso-rotate: 0;
  mso-background-source: auto;
  mso-pattern: auto;
  color: black;
  font-size: 11pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
  border: none;
  mso-protection: locked visible;
  mso-style-name: "표준 31";
}
td {
  mso-style-parent: style0;
  padding: 0px;
  mso-ignore: padding;
  color: black;
  font-size: 11pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
  mso-number-format: General;
  text-align: general;
  vertical-align: middle;
  border: none;
  mso-background-source: auto;
  mso-pattern: auto;
  mso-protection: locked visible;
  white-space: nowrap;
  mso-rotate: 0;
}
.xl66 {
  mso-style-parent: style17;
  color: #080000;
  font-size: 10pt;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  mso-number-format: "\@";
  border-top: 0.5pt hairline windowtext;
  border-right: none;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl67 {
  mso-style-parent: style0;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl68 {
  mso-style-parent: style0;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.xl69 {
  mso-style-parent: style0;
  border-top: 1pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl70 {
  mso-style-parent: style0;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl71 {
  mso-style-parent: style0;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl72 {
  mso-style-parent: style0;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
}
.xl73 {
  mso-style-parent: style0;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl74 {
  mso-style-parent: style0;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl75 {
  mso-style-parent: style0;
  text-align: center;
}
.xl76 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl77 {
  mso-style-parent: style0;
  font-weight: 700;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
  background: #ffe699;
  mso-pattern: black none;
}
.xl78 {
  mso-style-parent: style0;
  font-weight: 700;
  border-top: 1pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: none;
  background: #ffe699;
  mso-pattern: black none;
}
.xl79 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
  background: #ffe699;
  mso-pattern: black none;
}
.xl80 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.xl81 {
  mso-style-parent: style0;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl82 {
  mso-style-parent: style0;
  font-size: 10pt;
}
.xl83 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl84 {
  mso-style-parent: style0;
  font-weight: 700;
  mso-number-format: 0;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
  background: #ffe699;
  mso-pattern: black none;
}
.xl85 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: 0.5pt hairline windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl86 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl87 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
.xl88 {
  mso-style-parent: style0;
  color: black;
  font-size: 10pt;
  font-weight: 700;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
.xl89 {
  mso-style-parent: style0;
  color: windowtext;
  font-weight: 700;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
}
.xl90 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
}
.xl91 {
  mso-style-parent: style0;
  font-weight: 700;
  mso-number-format: "\#\,\#\#0\.00_\)\;\[Red\]\\\(\#\,\#\#0\.00\\\)";
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
  background: #ffe699;
  mso-pattern: black none;
}
.xl92 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.xl93 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl94 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl95 {
  mso-style-parent: style0;
  font-size: 10pt;
  mso-number-format: "Short Date";
}
.xl96 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: center;
}
.xl97 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
}
.xl98 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl99 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl100 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl101 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl102 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 1pt solid windowtext;
}
.xl103 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 0.5pt hairline windowtext;
  border-left: none;
}
.xl104 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: none;
}
.xl105 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl106 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: center;
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl107 {
  mso-style-parent: style0;
  font-size: 10pt;
  mso-number-format: "\#\,\#\#0\.00_\)\;\[Red\]\\\(\#\,\#\#0\.00\\\)";
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl108 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl109 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 0.5pt hairline windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 1pt solid windowtext;
}
.xl110 {
  mso-style-parent: style18;
  color: #080000;
  font-size: 10pt;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  text-align: center;
  border: 0.5pt hairline windowtext;
}
.xl111 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 0.5pt hairline windowtext;
  border-right: none;
  border-bottom: 0.5pt hairline windowtext;
  border-left: none;
}
.xl112 {
  mso-style-parent: style0;
  font-size: 10pt;
  border-top: 0.5pt hairline windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: none;
}
.xl113 {
  mso-style-parent: style0;
  font-size: 10pt;
  border: 0.5pt hairline windowtext;
}
.xl114 {
  mso-style-parent: style0;
  font-size: 10pt;
  text-align: center;
  border: 0.5pt hairline windowtext;
}
.xl115 {
  mso-style-parent: style0;
  font-size: 10pt;
  mso-number-format: "0\.00_ ";
  border: 0.5pt hairline windowtext;
}
.xl116 {
  mso-style-parent: style0;
  font-size: 10pt;
  mso-number-format: "\#\,\#\#0\.00_\)\;\[Red\]\\\(\#\,\#\#0\.00\\\)";
  border: 0.5pt hairline windowtext;
}
.xl117 {
  mso-style-parent: style0;
  font-size: 10pt;
  mso-number-format: "0\.00_ ";
  border-top: 0.5pt hairline windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl118 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  text-align: center;
  border: 0.5pt hairline windowtext;
}
.xl119 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl120 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.xl121 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.xl122 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: 1pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl123 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  text-align: left;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl124 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
}
.xl125 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  text-align: center;
}
.xl126 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: none;
}
.xl127 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl128 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl129 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl130 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  font-weight: 700;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl131 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 1pt solid windowtext;
}
.xl132 {
  mso-style-parent: style0;
  font-weight: 700;
  mso-number-format: "\#\,\#\#0\.00_\)\;\[Red\]\\\(\#\,\#\#0\.00\\\)";
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
  background: #ffe699;
  mso-pattern: black none;
}
.xl133 {
  mso-style-parent: style0;
  color: windowtext;
  font-size: 10pt;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  text-align: left;
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl134 {
  mso-style-parent: style18;
  color: #080000;
  font-size: 10pt;
  font-family: Arial;
  mso-generic-font-family: auto;
  mso-font-charset: 0;
  text-align: left;
  border: 0.5pt hairline windowtext;
}
.xl135 {
  mso-style-parent: style0;
  font-size: 22pt;
  font-weight: 700;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl136 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 1pt solid windowtext;
  white-space: normal;
}
.xl137 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 0.5pt hairline windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: 1pt solid windowtext;
}
.xl138 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 0.5pt hairline windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl139 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 0.5pt hairline windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl140 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 0.5pt hairline windowtext;
}
.xl141 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 0.5pt hairline windowtext;
  border-bottom: none;
  border-left: none;
}
.xl142 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt hairline windowtext;
}
.xl143 {
  mso-style-parent: style0;
  font-weight: 700;
  text-align: center;
  border-top: none;
  border-right: 0.5pt hairline windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: none;
}
.xl144 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: 1pt solid windowtext;
  border-bottom: none;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
.xl145 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: none;
  border-right: 1pt solid windowtext;
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
.xl146 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: 1pt solid windowtext;
  border-right: none;
  border-bottom: none;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
.xl147 {
  mso-style-parent: style0;
  font-size: 10pt;
  font-weight: 700;
  text-align: center;
  border-top: none;
  border-right: none;
  border-bottom: 1pt solid windowtext;
  border-left: 0.5pt hairline windowtext;
  white-space: normal;
}
ruby {
  ruby-align: left;
}
rt {
  color: windowtext;
  font-size: 8pt;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  font-family: "맑은 고딕";
  mso-generic-font-family: auto;
  mso-font-charset: 129;
  mso-char-type: none;
  display: none;
}
